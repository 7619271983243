<template>
  <div class="intro">
      <div>
        <div class="step-title">
          <div v-html="title"></div>
        </div>
        <div class="step-box">
          如果 App 内无法充值<br>
          请使用网页充值<br><br>
          <a href="/app/webpay.html" class="step-button " :style="countDownEnd ? clickable : notClickable">网页充值</a><br><br>
          <img style="width: 40%;min-height: 5rem" src="@/assets/images/feature_super.png">
        </div>
      </div>
  </div>
</template>

<script>
  import { reactive, ref } from 'vue'
  import { useStore } from 'vuex'
  import {getDomain} from "@/config";

  export default {
    name: 'IOSAppstoreFaq',
    components: {},
    setup () {

      let appstoreUrl = ref('https://apps.apple.com/app/id1559485550')
      function setUrl () {
        this.$http.get(`${getDomain('oc')}/api/website/vue/ios-url`)
          .then((date) => {
            if (date.date.obj['ios-appstore-url']) appstoreUrl.value = date.date.obj['ios-appstore-url']
          })
          .catch((e) => {
            console.log(e)
          })
      }


      let countDownEnd = ref(true)
      let countDownNum = ref(3)

      let title = ref('&nbsp;AppStore&nbsp;充值解答')
      let clickable = reactive({
        'background-color' : '#2677FF',
        'pointer-events' : 'inherit'
      })
      let notClickable = reactive({
        'background-color' : '#d5d5d5',
        'pointer-events' : 'none'
      })

      function setCountDownToAppStore(url) {
        countDownEnd.value = false
        let countDownInterval = setInterval(function () {
          try {
            if (countDownNum.value === 1) {
              title.value = '&nbsp;AppStore&nbsp;充值解答'
              countDownEnd.value = true
              window.location.href = url
              window.clearInterval(countDownInterval)
            } else {
              countDownNum.value =  countDownNum.value - 1
              title.value = `即将跳转 &nbsp;&nbsp;(<span>${countDownNum.value}</span>)`
            }
          } catch (e) {
            window.clearInterval(countDownInterval)
          }
        }, 1000)
      }
      let store = useStore().state
      if (store.toIosAppStoreFaq)  setCountDownToAppStore(appstoreUrl.value)

      return {
        countDownNum,
        countDownEnd,
        notClickable,
        clickable,
        title,
        appstoreUrl,
        setUrl
      }
    },
    created () {
      this.setUrl()
    }

  }
</script>

<style scoped>

  .intro .step-title {
    background-color: #CC1424;
    color: white;
    width: 70%;
    max-width: 500px;
    margin: 0 auto 1.2rem auto;
    padding: 0.25rem;
    font-size: 1.5rem;
    border: 3px solid #4D2C22;
    border-radius: 10rem;
    box-shadow: 0 4px 0 0 #00000030;
    -webkit-box-shadow: 0 4px 0 0 #00000030;
    -moz-box-shadow: 0 4px 0 0 #00000030;
  }

  .intro .step-box {
    display: block;
    background-color: #EEEEEE;
    color: black;
    width: 70%;
    max-width: 500px;
    margin: 0 auto 1.2rem auto;
    padding: 1.5rem;
    font-size: 1.2rem;
    border: 3px solid #4D4D4D;
    border-radius: 2rem;
    box-shadow: inset 0 4px 0 0 white, 0 4px 0 0 #00000030;
    -webkit-box-shadow: inset 0 4px 0 0 white, 0 4px 0 0 #00000030;
    -moz-box-shadow: inset 0 4px 0 0 white, 0 4px 0 0 #00000030;
  }

   .intro .step-button, .intro a.step-button {
    background-color: #0066FF;
    border-radius: 20rem;
    border: none;
    color: white;
    padding: 0.2rem 1.2rem;
    display: inline-block;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
  }
  .intro #to-top-up{
    background-color: #d5d5d5;
    pointer-events: none;
  }
</style>
